.rdp__body {
  justify-content: center;
  width: none !important;
}

.rdp__days-item-active--start,
.rdp__days-item-active--range-start,
.rdp__days-item-active--range-end,
.rdp__days-item-active--end {
  border-radius: 50% !important;
  background-color: #014a62 !important;

  &:after {
    content: "";
    border-radius: 50% 0 0 50%;
    background-color: #014a62 !important;
    opacity: 0.5;
    // width: 100%;
    height: 22px;
    position: absolute;
  }
}

.rdp__days-item-active--range-start {
  &:after {
    width: 35.5px !important;
    left: 2px !important;
  }
}
.rdp__days-item-active--start {
  &:after {
    width: 36px !important;
    left: 2px !important;
  }
}

.rdp__days-item-active--end,
.rdp__days-item-active--range-end {
  &:after {
    content: "";
    border-radius: 0 50% 50% 0;
    background-color: #014a62 !important;
    opacity: 0.5;
    width: 37.5px;
    height: 22px;
    position: absolute;
    right: 0px;
  }
}

.rdp__days-item-active--range-start,
.rdp__days-item-active--range-end {
  color: #fff;
}

.rdp__days-item-active--range-connect {
  background-color: #fff !important;
  color: #000 !important;

  &:after {
    content: "";
    background-color: #014a62 !important;
    opacity: 0.5;
    width: 41px;
    height: 22px;
    position: absolute;
  }
}
.rdb__days-item-active--connect {
  background-color: #fff !important;
  color: #000 !important;

  &:after {
    content: "";
    background-color: #014a62 !important;
    opacity: 0.5 !important;
    width: 41px !important;
    height: 22px !important;
    position: absolute  !important;
  }
}

.rdb__days-item-active--connect {
  &:after {
    border-radius: 0px !important;
  }
}
.rdp__days-item-active--range-connect:last-child,
.rdb__days-item-active--connect:last-child {
  &:after {
    border-radius: 0 8px 8px 0 !important;
  }
}

.rdp__days-item-active--range-connect:first-child,
.rdb__days-item-active--connect:first-child {
  &:after {
    border-radius: 8px 0 0 8px !important;
  }
}

.rdp__days-item-active--range-end {
  border-radius: 0 50% 50% 0;
}

.rdp__days-item {
  font-size: 12px;
  width: 34px;
  height: 34px;
}


.rdp__labels-item {
  font-size: 12px;
}

.rdp__days-item-active--range-connect,
.rdp__days-item-active--range-start,
.rdp__days-item-active--range-end {
  border: none;
}


.rdp__body {
  left: -260px !important
}

.rdp__days-row {
  display: flex;
  justify-content: space-between;
}